import {
    db,
    ADD,
    UPDATE
} from '@/firebase.js'

var Rembrandt = require('rembrandt/build/browser')
import 'canvas'

export default {
    name: 'Poker',
    data: function () {
        return {
            search: null,
            domains: ['com', 'se', 'co'],
            urls: [],
            searchTimeout: null,
            successLoadCount: 0,
            errorLoadCount: 0,
            inputClicked: false,
            pokedCompanyRows: [0, 1, 2, 3, 4, 5],
            pickedPoke: false,
            pokeSuccessNote: null,
            pokedCompanies: [{
                    name: 'Lyko',
                    domain: 'Lyko.se'
                }, {
                    name: 'Zalando',
                    domain: 'Zalando.com'
                }, {
                    name: 'Spotify',
                    domain: 'spotify.se'
                },
                {
                    name: 'Voi',
                    domain: 'voiscooters.com'
                }, {
                    name: 'Ica',
                    domain: 'Ica.se'
                }, {
                    name: 'Holy Greens',
                    domain: 'holygreens.se'
                }, {
                    name: 'Coop',
                    domain: 'coop.se'
                }, {
                    name: 'Postnord',
                    domain: 'Postnord.se'
                }, {
                    name: 'Joe & the juice',
                    domain: 'joeandthejuice.se'
                }, {
                    name: 'Scandic',
                    domain: 'scandic.se'
                }, {
                    name: 'Elgiganten',
                    domain: 'elgiganten.se'
                }, {
                    name: 'MEDS',
                    domain: 'meds.se'
                }, {
                    name: 'Scania',
                    domain: 'scania.com'
                }, {
                    name: 'Foodora',
                    domain: 'Foodora.com'
                }, {
                    name: 'CDON',
                    domain: 'CDON.com'
                }, {
                    name: 'Max',
                    domain: 'Max.se'
                }, {
                    name: 'Blocket',
                    domain: 'Blocket.se'
                }, {
                    name: 'Tele2',
                    domain: 'Tele2.se'
                }, {
                    name: 'Kry',
                    domain: 'kry.se'
                }, {
                    name: 'Stayhard',
                    domain: 'stayhard.se'
                }, {
                    name: 'hm',
                    domain: 'hm.com'
                },
            ],
        }
    },
    methods: {
        onSearchChange: function (search) {
            this.urls = []
            this.domains.forEach(domain => {
                this.urls.push({
                    name: search.charAt(0).toUpperCase() + search.slice(1),
                    domain: domain,
                    img: '//logo.clearbit.com/' + search.replace(/ /g, '') + '.' + domain
                })
                this.$forceUpdate()
            })
            this.errorLoadCount = 0
            this.successLoadCount = 0

        },
        successLoad: function () {
            this.successLoadCount++
            if (this.successLoadCount === this.urls.length) {
                if (this.successLoadCount === 1) {
                    this.urls[0].show = true
                } else this.compareImages()
            }
        },
        errorLoad: function (index) {
            this.urls.splice(index, 1)
            if (this.successLoadCount === 1) {
                this.urls[0].show = true
            } else this.compareImages()
        },
        compareImages: function () {
            let urlCount = 0
            this.urls.forEach(url => {
                urlCount++
                if (!this.urls[urlCount]) return
                this.makeComparison(url, urlCount)
            })
        },
        sendNote: function () {
            if (!this.pokeSuccessNote) return
            let doc = db.collection('pokes').doc(this.pickedPoke.docId)
            UPDATE(doc, {
                note: this.pokeSuccessNote
            }).then(() => {
                this.pokeSuccessNote = 'note_sent'
            })
        },
        pokeACompany: function (url) {
            this.pickedPoke = url
            this.search = null
            let collection = db.collection('pokes')
            ADD(collection, {
                company: url.name,
                date: new Date()
            }).then(doc => {
                this.pickedPoke.docId = doc.id
            })
        },
        rowSwitchInterval: function () {
            setInterval(() => {
                this.pokedCompanyRows.push(this.pokedCompanyRows.length + 1)
            }, 20000)
        },
        makeComparison: function (url, index) {
            console.log(url.img)
            console.log(this.urls[index].img)
            const rembrandt = new Rembrandt({
                imageA: url.img,
                imageB: this.urls[index].img,
                maxThreshold: 0.01,
                maxDelta: 0.02,
                maxOffset: 0,
                renderComposition: false, // Should Rembrandt render a composition image?
                compositionMaskColor: Rembrandt.Color.RED // Color of unmatched pixels
            })

            // Run the comparison
            rembrandt.compare()
                .then(result => {
                    console.log(result.differences)
                    url.show = true
                    if (result.differences === 0) {
                        this.urls.splice(index, 1)
                    } else {
                        this.urls[index].show = true
                    }
                    this.$forceUpdate()
                })
                .catch((e) => {
                    console.error(e)
                })
        },

    },
    mounted() {
        let lottieScript = document.createElement('script')
        lottieScript.setAttribute('src', 'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js')
        document.head.appendChild(lottieScript)
    },
    created: function () {
        this.rowSwitchInterval()
    }
}